import consumer from "./consumer"

$(document).ready(function () {
  $.each($('.report-unloading-progress-bar'), function () {
    consumer.subscriptions.create({ channel: "ReportUnloadingChannel", id: $(this).data('id') }, {
      connected() {
        console.log("Connected to the channel:", this);
      },

      disconnected() {
        console.log("Disconnected");
      },

      received(data) {
        console.log("Received some data:", data);
        let at = data.at;
        let id = data.id;
        let time = data.time;
        let current_line = data.current_line
        let lines = data.lines

        if (at == 100) {
          $('.unloadings-count').removeClass('in-progress badge-light').addClass('badge-success');
          $('.unloadings-count').html('&check;');
          if (window.location.href.indexOf("unloadings") > -1) {
            window.location.reload();
          }else{
            $.ajax({
              method: 'GET',
              url: `/${data.env}/users/reset_unloadings`,
              dataType: 'script'
            })
          }
        } else {
          $(`.progress-field-unloading-${id}`).removeClass('d-none');
          $(`.progress-bar-unloading-${id}`).addClass(`wd-${at}p`);
          $(`.progress-bar-unloading-${id}`).text(`${at}%`);
          $(`.progress-field-unloading-${id} .progress-lines`).text(`${current_line} из ${lines}`);
          $(`.progress-field-unloading-${id} .progress-time`).text(`~ ${time} мин`);
          $(`.download-field-unloading-${id}`).text(`Выполняется. Осталось ~ ${time} мин`);
        }
        if (at > 0) {
          $(`.download-field-unloading-${id}`).text(`Выполняется. Осталось ~ ${time} мин`);
        }
      }
    });
  });
});
