$(document).on('click', 'a.remove_product_alias', function(){
  var parent = $(this).parent().parent();
  parent.remove();
});

$(document).ready(function(){
  $('a.show-development-winners-form').click(function(){
    $('#development-winners-form-modal').modal('show');

    $('.development-winners-select2').select2({
      language: {
        noResults: function(){
          return 'Участник не найден';
        },
        searching: function(){
          return 'Поиск';
        },
      },
      placeholder: 'Поиск участника',
      searchInputPlaceholder: 'Поиск',
      width: '100%',
      ajax: {
        dataType: 'json',
        processResults: function (data, page) {
          return { results: JQuery.map(data, function(engage, i) {
            return { id: engage.id, text: engage.text }
          } ) };
        }
      }
    });
  });
});

$(document).ready(function(){
  $('.nested_form_with_datetime').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    Datetimepicker.onInit();
  });

  $(document).click(function(){
    $('.switch-charts-value-representation .popover').removeClass('show')
  });

  $('.switch-charts-value-representation i').click(function(e){
    e.stopPropagation();
    $(this).parent().find('.popover').toggleClass('show');
  });

  $('.upload-participants-button').click(function(){
    $('#upload-participants-modal').modal('show')
  })
});

$(document).on('change', 'input.custom-file-input', function(e){
  var fileName = e.target.files[0].name

  $(this).parent().find('label.custom-file-label').text(fileName)
});

$(document).on('click', 'input.quick_update_participation_allowed', function(){
  var value = $(this).is(':checked') ? '1' : '0'
  var participantId = $(this).data('id')
  var projectId = $(this).data('project-id')
  var env = window.location.pathname.split('/')[1]
  var url = '/' + env + '/projects/' + projectId + '/participants/' + participantId + '/update_participation_allowed'

  $.ajax({
    url: url,
    type: 'PUT',
    data: { participation_allowed: value }
  });
})

// quick update engage allowed
$(document).on('click', 'input.quick_update_engage_participation_allowed', function(){
  var value = $(this).is(':checked') ? '1' : '0'
  var engageId = $(this).data('id')
  var projectId = $(this).data('project-id')
  var env = window.location.pathname.split('/')[1]
  var url = '/' + env + '/projects/' + projectId + '/engages/' + engageId + '/update_engage_participation_allowed'

  $.ajax({
    url: url,
    type: 'PUT',
    data: { participation_allowed: value }
  });
})

$(document).on('click', '#monitoring_project_test_benchmarks_inputs a.add_row', function(){
  var row = $(this).closest('.row')
  var cloneRow = row.clone()
  var addLink = cloneRow.find('.add_row')
  var linkContainer = addLink.parent()
  var removeLink = "<a href='javascript:;' class='remove_row'>удалить</a>"

  cloneRow.find('label').text('')
  addLink.remove()
  linkContainer.html(removeLink)
  cloneRow.find('input').each(function(){
    var oldId = $(this).attr('id')
    var newId = oldId + $('#monitoring_project_test_benchmarks_inputs .row').length + 1
    $(this).prop('id', newId)
  })

  cloneRow.insertAfter(row)
});

$(document).on('click', '#monitoring_project_test_benchmarks_inputs a.remove_row', function(){
  $(this).closest('.row').remove()
})

$(document).on('click', '#project_wallets_inputs a.add_new_wallet', function(){
  var row = $('#project_wallets_template .row').last()
  var cloneRow = row.clone()

  cloneRow.find('input').each(function(){
    var oldId = $(this).attr('id')
    var newId = oldId + String($('#project_wallets_inputs .row').length + 1)
    $(this).prop('id', newId)
  })

  cloneRow.find('select').each(function(){
    var oldId = $(this).attr('id')
    var newId = oldId + String($('#project_wallets_inputs .row').length + 1)
    $(this).prop('id', newId)
  })

  cloneRow.insertBefore($('#project_wallets_inputs .add_new_wallet_link'))
})

$(document).on('click', '#project_wallets_inputs a.remove_wallet', function(){
  $(this).closest('.row').remove()
})

$(document).ready(function(){
  // WTF?
  $('.select2').select2({
    language: {
      noResults: function(){
        return 'Город не найден';
      }
    },
    placeholder: 'Выберите город',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: '/api/v1/geo/cities.json',
      dataType: 'json',
      processResults: function (data, page) {
          // parse the results into the format expected by Select2.
          // since we are using custom formatting functions we do not need to
          // alter the remote JSON data
          return { results: JQuery.map(data, function(city, i) {
            return { id: city.id, text: city.name }
          } ) };
        }
    }
  });

  $('.select2-settlement-id').select2({
    language: {
      noResults: function(){
        return 'Город не найден';
      }
    },
    placeholder: 'Выберите город',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: "/api/v4/geo/settlements.json?",
      headers: {
        'Api-key': $('#project-api-key').data('value')
      },
      dataType: 'json',
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(settlement, i) {
            return { id: settlement.id, text: settlement.name }
          } ) };
        }
    }
  });

  $('.simple-multiple-select2').select2({
    width: '100%',
    multiple: true
  });

  $('.simple-multiple-select2-with-tags').select2({
    width: '100%',
    multiple: true,
    tags: true
  });

  $('.filter-by-categories-select2').select2({
    width: '100%',
    multiple: true,
    placeholder: 'Все'
  });

  $('.simple-select2').select2({
    width: '100%',
    multiple: false,
    allowClear: true,
    placeholder: 'Все'
  });

  $('#list-filters .products-select2').select2({
    language: {
      noResults: function(){
        return 'Товар не найден';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    placeholder: 'Выберите товары',
    multiple: true,
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: '/api/v1/products/all_products.json',
      dataType: 'json',
      processResults: function (data, page) {
          // parse the results into the format expected by Select2.
          // since we are using custom formatting functions we do not need to
          // alter the remote JSON data
          return { results: JQuery.map(data, function(product, i) {
            return { id: product.name, text: product.name }
          } ) };
        }
    }
  });

  $('.trusted_reject_states-select2').select2({
    language: {
      noResults: function(){
        return 'Статус отклонения не найден';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    placeholder: 'Выберите статус отклонения',
    multiple: true,
    searchInputPlaceholder: 'Поиск',
    width: '100%'
  });

  $('.receipt-payment-types-select2').select2({
    language: {
      noResults: function(){
        return 'Тип оплаты не найден';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    placeholder: 'Выберите тип оплаты',
    multiple: true,
    searchInputPlaceholder: 'Поиск',
    width: '100%'
  });

  $('.select2-tickets-filter').select2({
    placeholder: 'Выберите тему',
    multiple: true,
    searchInputPlaceholder: 'Поиск'
  });

  $('.select2-solutions-filter').select2({
    placeholder: 'Выберите задание',
    multiple: true,
    searchInputPlaceholder: 'Поиск'
  });

  function contragetn_filter() {
    $('.select2-contragents-filter').select2({
      language: {
        noResults: function(){
          return 'Контрагент не найден';
        },
        searching: function(){
          return 'Поиск';
        },
        inputTooShort: function (args) {
          return `Пожалуйста, введите ещё ${args.minimum - args.input.length} или более символов`;
        }
      },
      placeholder: 'Выберите контрагента',
      multiple: false,
      searchInputPlaceholder: 'Поиск',
      minimumInputLength: 3,
      ajax: {
        url: '/api/v1/contragents/all_contragents.json',
        dataType: 'json',
        processResults: function (data, page) {
          var selectedInns = $("#inns-validation-container").data('ids')
          $('#inns-validation-container .nested-fields:visible option:selected').each(function(){
            var val = String($(this).val())

            selectedInns.push(val)
          })

          return { results: JQuery.map(data, function(contragent, i) {
            let title = function() {
              if (contragent.name == '') { return contragent.inn; }
              return `${contragent.inn} - ${contragent.name}`
            }();

            if(!selectedInns.includes(String(contragent.id))) {
              return { id: contragent.id, text: title }
            }
          } ) };
        }
      }
    });
  }

  function geo_settlement_filter() {
    $('.select2-geo-settlement-filter').select2({
      language: {
        noResults: function(){
          return 'Населенный пункт не найден';
        },
        searching: function(){
          return 'Поиск';
        },
        inputTooShort: function (args) {
          return `Пожалуйста, введите ещё ${args.minimum - args.input.length} или более символов`;
        }
      },
      placeholder: 'Выберите населенный пункт',
      multiple: true,
      searchInputPlaceholder: 'Поиск',
      minimumInputLength: 3,
      ajax: {
        url: $('#receipt_validation_config_settlement_ids').data('url'),
        dataType: 'json',
        processResults: function (data, page) {
            return { results: JQuery.map(data, function(settlement, i) {
              return { id: settlement.id, text: settlement.name }
            } ) };
          }
      }
    });
  }

  $('form').on('cocoon:after-insert', function() {
    contragetn_filter();
    geo_settlement_filter();
  });

  contragetn_filter();
  geo_settlement_filter();

  $('ol.breadcrumb li').each(function(){
    $(this).addClass('breadcrumb-item');

    if($(this).find('a').length < 1)
    {
      $(this).addClass('active');
    }
  });

  $('a.javascript_receipts_report').click(function(){
    var url = $(this).data('url')
    var serializedParams = $('#list-filters').serialize()
    var urlWithParams = url + '/?' + serializedParams

    $.ajax({
      method: 'GET',
      url: urlWithParams
    }).success(function(data){
      $('#report-notice-modal').modal();
    })
  });

  $('a.javascript_all_participants_report').click(function(){
    var url = $(this).data('url')
    var serializedParams = $('#all-participants-filters').serialize()
    var urlWithParams = url + '&' + serializedParams

    window.location.href = urlWithParams
  });
});

// Lotteries table
$(document).ready(function() {
  // # NOTE: Change also set of columns in controller
  columns = [
    { 'data': 'id', 'render': function(data, type, row, meta){
      var viewUrl = data['current_env'] + '/projects/' + data['project_id'] + '/lotteries/' + data['id'] + '?tab=status';
      var viewLink = "<a href='/" + viewUrl + "'>" + data['id'] + "</a>";
      return viewLink;
    }},
    { 'data': 'name', 'render': function(data, type, row, meta){
      var viewUrl = data['current_env'] + '/projects/' + data['project_id'] + '/lotteries/' + data['lottery_id'] + '?tab=status';
      var viewLink = "<a href='/" + viewUrl + "'>" + data['name'] + "</a>";
      return viewLink;
    }},
    { 'data': 'state'},
    { 'data': 'prize_name', orderable: false },
    { 'data': 'run_at' },
    { 'data': 'tickets_count', orderable: false },
    { 'data': 'winner_id', orderable: false },
    { 'data': 'winner_full_name', orderable: false },
    { 'data': 'actions', orderable: false }
  ]
  if($('#multiple_lotteries_actions').length > 0)
  {
    columns.unshift({ 'data': 'checkbox', orderable: false })
  }

  var lotteries_table = $('#lotteries_table').DataTable({
    'searching': false,
    'paging': true,
    'info': false,
    'serverSide': true,
    'processing': true,
    'columns': columns,
    order: [[1, 'desc']],
    'ajax': {
      'url': $('#lotteries_table').data('url'),
      'dataSrc': 'data',
      'data': function(d)
      {
        var data = convertSerializedParamsToHash($('#lotteries-table-filters').serializeArray())
        return $.extend({}, d, data)
      }
    },
    'drawCallback': function(){
      $('input.select_lottery_id').on('change', function(){
        var any_checked = $('input.select_lottery_id:checked').length > 0

        if(any_checked)
        {
          $('#multiple_lotteries_actions').removeClass('hidden')
        }
        else
        {
          $('#multiple_lotteries_actions').addClass('hidden')
        }
      })

      $('#multiple_lotteries_actions a').click(function(e){
        e.preventDefault()

        if(confirm('Вы уверены?'))
        {
          var params = '?'
          var url = $(this).data('url')
          $('input.select_lottery_id:checked').each(function(){
            params = params + 'lottery_ids[]=' + $(this).val() + '&'
          })

          $.get(url + params)
        }
      })
    }
  });

  // searching
  var typingTimer;                //timer identifier
  var doneTypingInterval = 2000;  //time in ms, 5 second for example
  var lotteriesTableInputs = $('#lotteries-table-filters input')
  var lotteryParticipantsInputs = $('#lottery-participants-filters input')

  if(lotteriesTableInputs.length > 0)
  {
    var input = lotteriesTableInputs
  }
  else
  {
    var input = lotteryParticipantsInputs
  }

  //on keyup, start the countdown
  input.on('keyup', function () {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  });

  //on keydown, clear the countdown
  input.on('keydown', function () {
    clearTimeout(typingTimer);
  });

  //user is "finished typing," do something
  function doneTyping () {
    if(lotteriesTableInputs.length > 0)
    {
      lotteries_table.draw();
    }
    else {
      var data = convertSerializedParamsToHash($('#lottery-participants-filters').serializeArray())
      var url = $(this).closest('form').attr('action')

      $.ajax({ url: url, data: data })
    }
  }

  // Engages data table
  if ($('#engages_table').length){
    var baseColumns = $('#engages_table thead th').map(function(){

      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }
      return $(this).data('name') !== undefined ? data : null;
    });

    var customDataColumnRow = [
      { 'data': 'id', orderable: false,targets: 0, 'render': function(data, type, row, meta){
          return "<a href='/" + row.links['current_env'] + "/projects/" + row.links['project_id'] + "/engages/" + row.links['engage_id'] + "'>"+ row['id'] +"</a>";
        }}
    ]

    var viewerColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var viewLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/engages/" + data['engage_id'] + "'>Просмотр</a>";
        return viewLink;
      }}
    ]

    var editorColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        //var viewLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/participants/" + data['participant_id'] + "'>Просмотр</a>";
        var editLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/engages/" + data['engage_id'] + "/edit'>Редактировать</a>";

        var quickEditLink = "<a href='/all_engages/" + data['engage_id'] + "/quick_edit' data-remote='true'>Быстрое редактирование</a>";

        var links = []
        if($('#engages_table').data('project-kind') === 'survey_form')
        {
          links = [editLink, quickEditLink]
        }
        else
        {
          links = [editLink]
        }

        return links.join(' | ');
      }}
    ]

    function participantsExtraColumns(){
      var currenRole = $('#engages_table').data('role');
      if (currenRole == 'viewer'){
        return viewerColumns;
      }else if(currenRole == 'editor'){
        return editorColumns;
      }
    }

    var columns = jQuery.merge(baseColumns, participantsExtraColumns())
    var orderIndex = $('thead tr th.defaultOrder').index()

    $('#engages_table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 100,
      'order': [[orderIndex, 'desc']],
      // # NOTE: Change also set of columns in controller
      'columns': columns,
      'columnDefs': customDataColumnRow,
      "pageLength": 50,
      'ajax': {
        'url': $('#engages_table').data('url'),
        'type': 'POST',
        'dataSrc': 'data'
      }
    });
  }

  // Participants data table
  if ($('#participants_table').length){
    var baseColumns = $('#participants_table thead th').map(function(){

      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }
      return $(this).data('name') !== undefined ? data : null;
    });

    var customDataColumnRow = [
      { 'data': 'id', orderable: false,targets: 0, 'render': function(data, type, row, meta){
          return "<a href='/" + row.links['current_env'] + "/projects/" + row.links['project_id'] + "/participants/" + row.links['participant_id'] + "'>"+ row['id'] +"</a>";
        }}
    ]

    var viewerColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var viewLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/participants/" + data['participant_id'] + "'>Просмотр</a>";
        return viewLink;
      }}
    ]

    var editorColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        //var viewLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/participants/" + data['participant_id'] + "'>Просмотр</a>";
        var editLink = "<a href='/" + data['current_env'] + "/projects/" + data['project_id'] + "/participants/" + data['participant_id'] + "/edit'>Редактировать</a>";

        var quickEditLink = "<a href='/all_participants/" + data['participant_id'] + "/quick_edit' data-remote='true'>Быстрое редактирование</a>";

        var links = []
        if($('#participants_table').data('project-kind') === 'survey_form')
        {
          links = [editLink, quickEditLink]
        }
        else
        {
          links = [editLink]
        }

        return links.join(' | ');
      }}
    ]

    function participantsExtraColumns(){
      var currenRole = $('#participants_table').data('role');
      if (currenRole == 'viewer'){
        return viewerColumns;
      }else if(currenRole == 'editor'){
        return editorColumns;
      }
    }

    var columns = jQuery.merge(baseColumns, participantsExtraColumns())
    var orderIndex = $('thead tr th.defaultOrder').index()

    $('#participants_table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 100,
      'order': [[orderIndex, 'desc']],
      // # NOTE: Change also set of columns in controller
      'columns': columns,
      'columnDefs': customDataColumnRow,
      "pageLength": 50,
      'ajax': {
        'url': $('#participants_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // Projects datatable
  if ($('#projects_table').length){
    var baseColumns = $('#projects_table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var customColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var viewLink = "<a href='/" + data['current_env'] + "/projects/" + data['id'] + "'>Просмотр</a>";
        if(data['can_edit'] == true)
        {
          var editLink = "<a href='/" + data['current_env'] + "/projects/" + data['id'] + "/edit'>Редактировать</a>";

          return viewLink + " | " + editLink;
        }
        else
        {
          return viewLink
        }
      }}
    ]

    var columns = jQuery.merge(baseColumns, customColumns)

    $('#projects_table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'order': [[0, 'desc']],
      // NOTE: Change also set of columns in controller
      'columns': columns,
      'ajax': {
        'url': $('#projects_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // Contragents datatable
  if ($('#contragents_table').length){
    var baseColumns = $('#contragents_table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var customColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var viewLink = "<a href='/contragents/" + data['id'] + "'>Просмотр</a>";

        return viewLink;
      }}
    ]

    var columns = jQuery.merge(baseColumns, customColumns)

    $('#contragents_table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'order': [[5, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#contragents_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // All participants data table
  if ($('#all-participants-table').length){
    var baseColumns = $('#all-participants-table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var editorColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var quickEditLink = "<a href='/all_participants/" + data['participant_id'] + "/quick_edit' data-remote='true'>Быстрое редактирование</a>";
        var authorized = $('#all-participants-table').data('authorized-quick-update') == ''
        return authorized ? quickEditLink : '';
      }}
    ]

    var columns = jQuery.merge(baseColumns, editorColumns)

    var allParticipants = $('#all-participants-table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 100,
      'order': [[0, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#all-participants-table').data('url'),
        'dataSrc': 'data',
        'data': function(d){
          var searchRangeData = convertSerializeToHash($('#all-participants-filters').serializeArray());

          return $.extend({}, d, searchRangeData);
        }
      },
      "drawCallback": function( settings ) {
        var api = this.api()
        var counter = api.page.info().recordsTotal

        $('#all-participants-filters .count-all-records').text(counter)
      }
    });

    $('#all-participants-filters select').change(function(){
      allParticipants.draw();
    })

    $('#all-participants-filters .date input').datepicker({
      onSelect: function(dateText, inst){
        allParticipants.draw();
      }
    });

    $('#all-participants-filters .date input').change(function(){
      allParticipants.draw();
    })
  }

  // LogItem data table
  if ($('#log-items-table').length){
    var columns = $('#log-items-table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var logItemsTable = $('#log-items-table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 100,
      'order': [[0, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#log-items-table').data('url'),
        'dataSrc': 'data',
        'data': function(d){
          var searchRangeData = convertSerializeToHash($('#log-items-filters').serializeArray());

          return $.extend({}, d, searchRangeData);
        }
      }
    });
  }

  // Companies data table
  if ($('#companies-table').length){
    var baseColumns = $('#companies-table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var customColumns = [
      { 'data': 'links', orderable: false, 'render': function(data, type, row, meta){
        var showLink = "<a href='/companies/" + data['company_id'] + "'>Просмотр</a>";
        var editLink = "<a href='/companies/" + data['company_id'] + '/edit' + "'>Редактировать</a>";
        var destroyLink = "<a href='/companies/" + data['company_id'] + "' data-method='delete' data-confirm='Вы уверены?' rel='nofollow'>Удалить</a>";
        return [showLink, editLink, destroyLink].join(' | ')
      }}
    ]

    var columns = jQuery.merge(baseColumns, customColumns)

    var companies = $('#companies-table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 10,
      'order': [[0, 'asc']],
      'columns': columns,
      'ajax': {
        'url': $('#companies-table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // Transactions data table
  if ($('#transactions-table').length){
    var columns = $('#transactions-table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var transactions = $('#transactions-table').DataTable({
      'searching': false,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'iDisplayLength': 10,
      'order': [[0, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#transactions-table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  $('.survey-projects-select2').select2({
    language: {
      noResults: function(){
        return 'Событие не найдено';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    allowClear: true,
    placeholder: 'Все',
    multiple: true,
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      dataType: 'json',
      processResults: function (data, page) {
        return { results: JQuery.map(data, function(project, i) {
          return { id: project.id, text: project.name }
        } ) };
      }
    }
  });

  $('.participant-companies-select2').select2({
    language: {
      noResults: function(){
        return 'Компания не найдена';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    allowClear: true,
    placeholder: 'Все',
    multiple: false,
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: '/api/v1/participants/companies',
      dataType: 'json',
      processResults: function (data, page) {
        return { results: JQuery.map(data, function(project, i) {
          return { id: project.id, text: project.name }
        } ) };
      }
    }
  });

  $('#segment_profiles_table').DataTable({
    'searching': true,
    'paging': true,
    'info': false,
    'serverSide': true,
    'processing': true,
    'order': [[0, 'desc']]
  });

  // Receipts datatable
  if ($('#receipts_table').length){
    var baseColumns = $('#receipts_table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var receiptColumns = [
      { 'data': 'id', orderable: true, 'render': function(data, type, row, meta){
        var receiptLink = "<a href='/" + data['env'] + "/projects/" + data['project_id'] + "/receipts/" + data['id'] + "/info" + "'>" + data['id'] + "</a>";

        return receiptLink;
      }}
    ]
    var contragentColumns = [
      { 'data': 'contragent', orderable: false, 'render': function(data, type, row, meta){
        if(data['contragent_id'] !== null)
        {
          var contragentLink = "<a href='/contragents/" + data['contragent_id'] + "'>" + data['contragent_name'] + "</a>";
          var contragentName = data['contragent_name'];
        }else{
          var contragentLink = '';
          var contragentName = '';
        }

        return $('#receipts_table .contragent-link').length ? contragentLink : contragentName;
      }}
    ]
    var participantColumns = [
      { 'data': 'participant', orderable: false, 'render': function(data, type, row, meta){
        var participantLink = "<a href='/" + data['env'] + "/projects/" + data['project_id'] + "/participants/" + data['id'] + "'>" + data['name'] + "</a>";
        if(data['authorized'] === false)
        {
          participantLink = data['name']
        }

        return participantLink;
      }}
    ]
    var engageColumns = [
      { 'data': 'engage', orderable: false, 'render': function(data, type, row, meta){
        var engageLink = "<a href='/" + data['env'] + "/projects/" + data['project_id'] + "/engages/" + data['id'] + "'>" + data['name'] + "</a>";
        if(data['authorized'] === false)
        {
          engageLink = data['name']
        }

        return engageLink;
      }}
    ]
    var projectColumns = [
      { 'data': 'project', orderable: false, 'render': function(data, type, row, meta){
        var participantLink = "<a href='" + data['link'] + "'>" + data['name'] + "</a>";

        return participantLink;
      }}
    ]

    var columns = receiptColumns
    columns = jQuery.merge(columns, baseColumns)
    columns = jQuery.merge(columns, projectColumns)
    columns = jQuery.merge(columns, engageColumns)
    columns = jQuery.merge(columns, contragentColumns)

    var receipts_table = $('#receipts_table').DataTable({
      'searching': true,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'order': [[0, 'desc']],
      // NOTE: Change also set of columns in controller
      'columns': columns,
      'ajax': {
        'url': $('#receipts_table').data('url'),
        'dataSrc': 'data',
        'data': function(d){
          var searchRangeData = convertSerializeToHash($('#list-filters').serializeArray());

          return $.extend({}, d, searchRangeData);
        }
      }
    });

    var typingTimer;
    var doneTypingInterval = 2000;
    var input = $('#list-filters input');

    // on keyup, start the countdown
    input.on('keyup', function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        receipts_table.draw();
      }, doneTypingInterval);
    });

    // on keydown, clear the countdown
    input.on('keydown', function () {
      clearTimeout(typingTimer);
    });

    $('#list-filters select.autosubmit').change(function(){
      receipts_table.draw();
    })
    $('.apply-filter').click(function(e){
      e.preventDefault();
      receipts_table.draw();
    })
  }

  // Engage receipts table
  if ($('#engage_receipts_table').length){
    var columns = $('#engage_receipts_table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var engage_receipts_table = $('#engage_receipts_table').DataTable({
      'searching': false,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'order': [[0, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#engage_receipts_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // Engage codes table
  if ($('#engage_codes_table').length){
    var columns = $('#engage_codes_table thead th').map(function(){
      var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }

      return $(this).data('name') !== undefined ? data : null;
    });

    var engage_codes_table = $('#engage_codes_table').DataTable({
      'searching': false,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'order': [[5, 'desc']],
      'columns': columns,
      'ajax': {
        'url': $('#engage_codes_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  // All profiles table
  var all_profiles_table = $('#profiles_table').DataTable({
    'searching': true,
    'paging': true,
    'info': false,
    'serverSide': true,
    'processing': true,
    'pageLength': 100,
    'order': [[4, 'desc']],
    'columns': [
      {
        'data': 'display_id', orderable: false,
        "render": function ( data, type, full, meta ) {
                    return '<a href="/cdp/profiles/' + full.id + '" class="link" target="_blank">'+ data + '</a>';
                  }
      },
      { 'data': 'full_name', orderable: false },
      { 'data': 'email', orderable: false },
      { 'data': 'phone', orderable: false },
      { 'data': 'created_at', orderable: true },
      {
        'data': 'id', orderable: false,
        "render": function ( data, type, full, meta ) {
                    return '<a href="/cdp/profiles/' + data + '" class="link" target="_blank">Просмотр</a>';
                  }
      }
    ],
    'ajax': {
      'url': $('#profiles_table').data('url'),
      'dataSrc': 'data'
    }
  });

  // Deliveries table
  $('#deliveries_table').DataTable({
    'searching': true,
    'paging': true,
    'info': false,
    'serverSide': true,
    'processing': true,
    'ordering': true,
    'order': [[0, 'desc']],
    // # NOTE: Change also set of columns in controller
    'columns': [
      { 'data': 'id', orderable: true },
      { 'data': 'prize_name', orderable: false },
      { 'data': 'created_at', orderable: true },
      { 'data': 'win_at', orderable: false },
      { 'data': 'lottery_id', orderable: false },
      { 'data': 'reason_id', orderable: false },
      { 'data': 'full_name', orderable: false },
      { 'data': 'phone', orderable: false },
      { 'data': 'email', orderable: false },
      { 'data': 'full_address', orderable: false }
    ],
    'ajax': {
      'url': $('#deliveries_table').data('url'),
      'dataSrc': 'data'
    }
  });
});

// Receipt Image Cropper
$(document).on('initCrop', '#modal-crop-image .image-container', function(){
  receiptImageCropper();
});

$(document).on('click', '#crop-image-form a.submit', function(){
  var $image = $('#modal-crop-image .image-container img');
  var cropper = $image.data('cropper');
  var data = cropper.getData(true);

  $('input#image_height').val(data['height']);
  $('input#image_rotate').val(data['rotate']);
  $('input#image_scale_x').val(data['scaleX']);
  $('input#image_scale_y').val(data['scaleY']);
  $('input#image_width').val(data['width']);
  $('input#image_x').val(data['x']);
  $('input#image_y').val(data['y']);

  var form = document.getElementById('crop-image-form');
  Rails.fire(form, 'submit');
});

$('form.auto_submit').change(function(){
  Rails.fire(this, 'submit');
});

function receiptImageCropper()
{
  var $image = $('#modal-crop-image .image-container img');

  $image.cropper({
    guides: true,
    highlight: true,

    zoom: function(event){
      $('input#image_ratio').val(event.ratio);
    }
  });

  // Get the Cropper.js instance after initialized
  var cropper = $image.data('cropper');

  $("button[data-method='rotate']").click(function(){
    if(cropper) {
      $image.cropper('rotate', $(this).data('option'));
    }
  });
}

export function parseUrl(string)
{
  var result = RegExp('[\?&]' + string + '=([^&#]*)').exec(window.location.href);
  return result === null ? '' : result[1];
}

export function convertSerializeToHash(serializedArray)
{
  var obj = {};
  for(var i=0; i < serializedArray.length; i++)
  {
    var a = serializedArray[i];
    var name = a.name;
    var value = a.value;

    if(
        name == 'filters[products_names][]' ||
        name == 'filters[by_categories][]' ||
        name == 'filters[by_project_ids][]'
     )
    {
      if(typeof obj[name] === 'undefined')
      {
        obj[name] = []
      }
      obj[name].push(value)
    }
    else
    {
      obj[name] = value
    }

  }
  return obj
}

$(document).on('change', "input[name='receipt[kind]']", function(){
  var form = $(this).parents('form');
  $( this ).parents('.dependet-fields').find('input').each(function() {
    form.removeClass( $(this).attr('value') );
  });
  form.addClass( $(this).val() );
});

$(document).on('click', '.js-receipt-validation-without-fns', function(){
  if (confirm('Вы уверены?')){
    $("form input[name='without_fns']").click();
    $('#success-msg').html("<p class='mg-b-20 mg-x-20'>Спасибо, чек отправлен на валидацию по условиям промо.</p>")
    $('#notice-modal').modal('show');
  }
});

$(document).on('click', ".accordion a[data-toggle='collapsed']", function(e){
  e.preventDefault();
  var targetId = $(this).attr('href');
  $(this).parents('.accordion').find("a[data-toggle='collapsed']").addClass('collapsed');
  $(this).parents('.accordion').find("a[data-toggle='collapsed']").attr('aria-expanded', 'false');
  $(this).parents('.accordion').find('.collapse').removeClass('show');
  $(this).removeClass('collapsed');
  $(this).attr('aria-expanded', 'true');
  $(targetId).addClass('show');
});

$(document).ready(function(){
  var show = localStorage.getItem('participantsFilters');
  if(show == 'show')
  {
    $('#participantsFiltersAccordion .collapse').addClass('show')
  }
})

$(document).on('click', "#participantsFiltersAccordion a.collapsed", function(e){
  e.preventDefault();
  var show = localStorage.getItem('participantsFilters');
  var newValue = (show == 'show' ? 'hide' : 'show')

  localStorage.setItem('participantsFilters', newValue);

  $('#participantsFiltersAccordion .collapse').toggleClass('show');
});

function convertSerializedParamsToHash(serializedArray)
{
  var obj = {}
  var i = 0

  while(i < serializedArray.length)
  {
    var a = serializedArray[i]
    var name = a.name
    var value = a.value

    obj[name] = value
    i++
  }

  return obj
}

$(document).on('change', '#support-tickets-filters select', function(){
  var form = $('#support-tickets-filters')
  form.submit()
})

$(document).on('change', '#solutions-filters select', function(){
  var form = $('#solutions-filters')
  form.submit()
})

$(document).ready(function(){
  $('input#products_validation_by_names').click(function(){
    $('#products_validation_names').removeClass('d-none')
    $('#products_validation_sku').addClass('d-none')

    $('#auto-confirm-product-names-container').removeClass('d-none')
    $('#auto_confirm_product_names').prop('checked', true)
  })

  $('input#products_validation_by_sku').click(function(){
    $('#products_validation_names').addClass('d-none')
    $('#products_validation_sku').removeClass('d-none')

    $('#auto-confirm-product-names-container').addClass('d-none')
    $('#auto_confirm_product_names').prop('checked', false)
  })

  $('#products_validation_sku .products-select2').select2({
    language: {
      noResults: function(){
        return 'Товар не найден';
      },
      searching: function(){
        return 'Поиск';
      },
    },
    placeholder: 'Выберите товары',
    multiple: true,
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: '/api/v1/products/',
      dataType: 'json',
      processResults: function (data, page) {
        return { results: JQuery.map(data, function(product, i) {
          return { id: product.id, text: product.name }
        } ) };
      }
    }
  });

  $('a.test-email-template, a.test-sms-template').click(function(){
    var form = $(this).closest('form')
    var url = $(this).data('url')
    var template_id = $(this).data('template_id')
    var data = convertSerializedParamsToHash(form.serializeArray())
    data['_method'] = null

    $.post(url, data, { method: 'post' })
  })

  $('a.preview-email-namespaces, a.preview-sms-namespaces').click(function(){
    var url = $(this).data('url')
    var kind = ''

    if ($('select#email_template_kind, select#sms_template_kind').length)
    {
      kind = $('select#email_template_kind option:selected, select#sms_template_kind option:selected').val()
    }
    if ($('input#email_template_kind, input#sms_template_kind').length)
    {
      kind = $('input#email_template_kind, input#sms_template_kind').val()
    }
    url = url + '?kind=' + kind

    $.get(url)
  })

  $('label a.development_url_question').click(function(){
    $('#development-url-question-modal').modal('show')
  })

  if($('#lottery_algorithm').length > 0){
    algorithmDescription()
  }

  $('select#lottery_algorithm').change(function(){
    algorithmDescription()
  })

  $("#lottery_lottery_version_v2").on( 'click', function(){
    algorithmDescription()
  })
})

function algorithmDescription(){
  var selectedAlgorithm = $('select#lottery_algorithm option:selected').val()

  $('#algorithm_description p').addClass('d-none')
  $('#algorithm_description').find("p[data-algorithm='" + selectedAlgorithm + "']").removeClass('d-none')

  var versionElem = $("input[name='lottery[lottery_version]']:checked")
  if(versionElem.length) {
    if(versionElem.val() == 'v2') {
      manageAlgorithmExtraFields(selectedAlgorithm)
    }
  }

  $.ajax({
    method: 'GET',
    url: 'algorithm_defaults?algorithm=' + $('select#lottery_algorithm option:selected').val(),
    dataType: 'json'
  }).done(function(response){
    if((window.location.href.includes('edit') && $('#lottery_params').val() == "") || window.location.href.includes('new')){
      $('#lottery_params').val(response.algorithm_defaults)
    }
  })
}

function manageAlgorithmExtraFields(selectedAlgorithm) {
  $('#algorithm-extra-fields input').prop('disabled', true)
  $('#prevent-duplicates-field input').prop('disabled', false)

  $('#algorithm-extra-fields .row').addClass('d-none')

  if(selectedAlgorithm == 'LotteryAlgorithm::ByMultiplier') {
    $('#winner-multiplier-field').removeClass('d-none')
    $('#winner-multiplier-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::TicketsCountWithCbr') {
    $('#max-prizes-count-field').removeClass('d-none')
    $('#max-prizes-count-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::SimpleCalculatedPrizesCount') {
    $('#prizes-count-limits-field').removeClass('d-none')
    $('#prizes-count-limits-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::CodesBySelectedPrize') {
    $('#prizes-count-field').removeClass('d-none')
    $('#prizes-count-field input').prop('disabled', false)

    $('#selected-prize-key-field').removeClass('d-none')
    $('#selected-prize-key-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::EngagesWithCbrDollar') {
    $('#max-prizes-count-field').removeClass('d-none')
    $('#max-prizes-count-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::ReceiptsNearestFp') {
    $('#fp-field').removeClass('d-none')
    $('#fp-field input').prop('disabled', false)
  }

  if(selectedAlgorithm == 'LotteryAlgorithm::CodesWithTicketsCount') {
    $('#min-winners-count-field').removeClass('d-none')
    $('#min-winners-count-field input').prop('disabled', false)

    $('#base-prizes-count-field').removeClass('d-none')
    $('#base-prizes-count-field input').prop('disabled', false)

    $('#winner-index-field').removeClass('d-none')
    $('#winner-index-field input').prop('disabled', false)
  }
}

$(document).on('click', 'a.add_new_ban_limit', function(){
  var idPrefix = $(this).data('id-prefix')
  var row = $('#' + idPrefix + '_project_ban_limits_template .row').last()
  var cloneRow = row.clone()

  cloneRow.find('input').each(function(){
    var oldId = $(this).attr('id')
    var newId = oldId + String($('#' + idPrefix + '_project_ban_limits_inputs .row').length + 1)
    $(this).prop('id', newId)
  })

  cloneRow.find('select').each(function(){
    var oldId = $(this).attr('id')
    var newId = oldId + String($('#' + idPrefix + '_project_ban_limits_inputs .row').length + 1)
    $(this).prop('id', newId)
  })

  cloneRow.insertBefore($('#' + idPrefix + '_project_ban_limits_inputs .add_new_ban_limit_link'))
})

$(document).on('change', '.project_ban_limits_ban_period_container select', function(){
  var value = $(this).find('option:selected').val();
  var banMinutesContainer = $(this).closest('.row').find('.project_ban_limits_ban_minutes_container')

  if(value == 'minutes')
  {
    banMinutesContainer.removeClass('hidden');
  }
  else
  {
    banMinutesContainer.addClass('hidden');
  }
})

$(document).on('click', 'a.remove_ban_limit', function(){
  $(this).closest('.row').remove()
})

$(document).on('click', 'a.open-new-block-list-modal', function(){
  $('#add-new-block-list-modal').modal('show')
});

$('a.showdatepicker').on('click', function(){
  $(this).parent().find('input').focus();
})

$(document).ready(function(){
  $('.datefilters .datepicker').each(function(){
    var id = $(this).attr('id');

    $("#" + id).datepicker({
      onSelect: function() {
        $(this).closest('form').submit();
      }
    })
  })
})

$(document).ready(function(){
  $('.datecolumns .datepicker').each(function(){
    var id = $(this).attr('id');

    $("#" + id).datepicker()
  })
})

$(document).ready(function(){
  $('form.external_service_credentials_form select').change(function(){
    var url = $('form.external_service_credentials_form').data('onchange-url')
    var val = $('form.external_service_credentials_form select option:selected').val()
    $.get(url, { selected_kind: val })
  })
})

$(document).ready(function(){
  var form = $('form.edit_engage');

  if(form.length > 0)
  {
    var email_was = $('form.edit_engage input[name="engage[email]"]').val();
    var phone_was = $('form.edit_engage input[name="engage[phone]"]').val();

    $('form.edit_engage a.submit').click(function(){
      var new_email = $('form.edit_engage input[name="engage[email]"]').val();
      var new_phone = $('form.edit_engage input[name="engage[phone]"]').val();
      var text = "";

      if(email_was != new_email)
      {
        text = text + "Вы меняете Email адрес пользователя, потребуется его повторное подтверждение. Письмо будет отправлено автоматически. Хотите продолжить? <br/>"
      }

      if(phone_was != new_phone)
      {
        text = text + "Вы меняете номер телефона пользователя, потребуется его повторное подтверждение. Хотите продолжить?<br/>"
      }

      if(text.length > 0)
      {
        $("#engages-cotacts-changed-modal .text").html(text);
        $("#engages-cotacts-changed-modal").modal('show')
      }
      else
      {
        $('form.edit_engage input[type="submit"]').click();
      }
    })

    $('a.confirm-submit').click(function(){
      $('form.edit_engage input[type="submit"]').click();
    })
  }
})

$().ready(function(){
  $('.double-sided-select a.add').click(function() {
    return !$('#double-sided-select-1 option:selected').remove().appendTo('#double-sided-select-2');
  });

  $('.double-sided-select a.remove').click(function() {
    return !$('#double-sided-select-2 option:selected').remove().appendTo('#double-sided-select-1');
  });

  $('a.up').click(function(){
    $('#double-sided-select-2 option:selected:first-child').prop("selected", false);
    var before = $('#double-sided-select-2 option:selected:first').prev();
    $('#double-sided-select-2 option:selected').detach().insertBefore(before);
  });

  $('a.down').click(function(){
    $('#double-sided-select-2 option:selected:last-child').prop("selected", false);
    var after = $('#double-sided-select-2 option:selected:last').next();
    $('#double-sided-select-2 option:selected').detach().insertAfter(after);
  });

  $('.double-sided-select-submit').click(function() {
    $('#double-sided-select-2 option').each(function(i) {
      $(this).prop("selected", true);
    });

    $(this).closest('form').submit();
  });
});

$(document).ready(function(){
  $('action-text-attachment').click(function(){
    var url = $(this).attr('url')

    window.open(url, '_blank').focus();
  })
});

$('.show-password-btn').on('click', function (e){
    $($(this).data('target')).attr('type', ()=>{
      return $($(this).data('target')).attr('type') === 'password' ? 'text' : 'password'
    })
  $(this).find('i.fas').toggleClass('fa-eye-slash').toggleClass('fa-eye')
})

$('#user_contract').on('change', function(e) {
  $('#contract_form').submit()
})

$('.support-data').on('change', function(e) {
  $('.update-support-data').removeClass('d-none')
})

$('.az-header-notification').on('click', function(e){
  $.ajax({
    method: 'GET',
    url: $(this).data('href'),
    dataType: 'script'
  })
})

$('.update-support-data').on('click', function(e) {
  e.preventDefault()

  var update_data = []
  $('.editable-true').each(function(){
    var id = $(this).attr('id').split('_')
    var hash = {}
    var data = {}
    $('.editable-true .support-data').each(function(){
      data[$(this).attr('name')] = $(this).val()
    })
    hash[id[1]] = data

    update_data.push(hash)
  })

  $.ajax({
    method: 'PUT',
    url: $(this).attr('href'),
    dataType: 'json',
    data: {'resources_data': JSON.stringify(update_data)}
  }).done(function() {
    window.location.reload()
  })
})

$('.typcn.typcn-info').on('mouseover', function(e) {
  $(this).tooltip('show')
})
$('.typcn.typcn-info').on('mouseout', function(e) {
  $(this).tooltip('hide')
})

$(document).on("change", "#reject-winner-form input[type='radio']", function(){
  var val = $(this).val()
  var textarea = "<textarea class='form-control' name='winner[reject_reason]' id='winner_reject_reason'></textarea>"
  var textareaContainer = $('#reject-winner-other-reason-input .col')

  if(val == 'other') {
    textareaContainer.html(textarea)
  } else {
    textareaContainer.html('')
  }
})

$(document).on('click', 'a.chat-qa', function(){
  alert('Запрос отправляется, ожидайте...')

  var url = $(this).data('url')
  $.get(url)
})

$(document).on('click', '.download-report', function(e){
  $('#report-notice-modal').modal();
})

$(document).on('click', '.unloadings-link', function(){
  $.ajax({
    method: 'PATCH',
    url: `/production/users/${$(this).data('userid')}/read_unloadings`,
    dataType: 'JSON'
  })
})

// $(document).on('click', '.stats-button-report', function(){
//   $.ajax({
//     method: 'GET',
//     url: $(this).attr('href')
//   })
// })

$(document).on('hidden.bs.modal', '#report-notice-modal', function(){
  window.location.reload()
})


$(document).ready(function(){
  if($('#email_campaigns_external_templates').length) {
    $('.preview-external-template').each(function(){
      var templateId = $(this).data('template-id')
      var updated = $(this).data('updated')
      var url = "/communications/email_campaigns/email_templates/template_image_preview?template_id=" + templateId + "&updated=" + updated + "&env=production"

      $.get(url)
    })
  }

  $('form input.with-image-preview').on('change', function(e){
    var file = e.target.files[0];
    var imagePreview = $(this).parent().find('.image-preview')

    if (file.type.match('image.*')) {
      var reader = new FileReader();

      reader.onload = function(e) {
        var img = $('<img>').attr('src', e.target.result);

        imagePreview.html(img)
      }

      reader.readAsDataURL(file);
    } else {
      alert('Загрузите картинку')
    }
  })

  $('form.edit_project .input-group.date').click(function(){
    alert('Внимание! Вы изменяете дату промо, все отчетные периоды будут сгенерированы заново. После обновления перейдите в раздел "Отчеты" и проверьте цифры.')
  })

  $('.new-promo-config').click(function(){
    $('#new-promo-config-modal').modal('show')
  })

  initPromoYamlCodeMirror()

  $('#new-promo-config-modal').on('shown.bs.modal', function() {
    window.PromoYamlCodeMirror.setSize('100%', 'auto')
    window.PromoYamlCodeMirror.refresh()
  });

  $('.image-with-size-value').each(function(){
    var img = new Image();
    var currentElem = $(this)

    img.onload = function() {
      currentElem.find('.value').text(this.width + 'x' + this.height + 'px');
    }
    img.src = currentElem.find('img').attr('src');
  })
})

function initPromoYamlCodeMirror() {
  if($('#promo_yaml_config').length) {
    var textarea = document.getElementById('promo_yaml_config')

    window.PromoYamlCodeMirror = window.CodeMirror.fromTextArea(
      textarea, {
        lineNumbers: true, styleActiveLine: true, mode: "yaml"
      }
    )
  }
}

$(document).on("click", ".show-modal", function(){
  var modalId = $(this).data('modal-id')
  $("#"+ modalId).modal('show')
})

$(document).ready(function(){
  manageProjectRegistrationFields()

  $('#project-registration-fields input').click(function() {
    var elemId = $(this).attr('id')

    if(elemId == 'email_display_field_checkbox') {
      if(!$(this).is(':checked')) {
        $("#phone_display_field_checkbox").prop('checked', true)
      }
    }

    if(elemId == 'phone_display_field_checkbox') {
      if(!$(this).is(':checked')) {
        $("#email_display_field_checkbox").prop('checked', true)
      }
    }
    manageProjectRegistrationFields()
  })

  $('#confirmation_fields_email_checkbox').click(function() {
    var displayElem = $("#email_display_field_checkbox")
    var requiredElem = $("#email_required_field_checkbox")

    if($(this).is(':checked')) {
      displayElem.prop('checked', true)
      displayElem.prop('disabled', true)
      displayElem.addClass('keep-disabled')

      requiredElem.prop('checked', true)
      requiredElem.prop('disabled', true)
      requiredElem.addClass('keep-disabled')
    } else {
      displayElem.removeAttr('disabled')
      displayElem.removeClass('keep-disabled')

      requiredElem.removeAttr('disabled')
      requiredElem.removeClass('keep-disabled')
    }
  })

  $('#confirmation_fields_phone_checkbox').click(function() {
    var displayElem = $("#phone_display_field_checkbox")
    var requiredElem = $("#phone_required_field_checkbox")

    if($(this).is(':checked')) {
      displayElem.prop('checked', true)
      displayElem.prop('disabled', true)
      displayElem.addClass('keep-disabled')

      requiredElem.prop('checked', true)
      requiredElem.prop('disabled', true)
      requiredElem.addClass('keep-disabled')
    } else {
      displayElem.removeAttr('disabled')
      displayElem.removeClass('keep-disabled')

      requiredElem.removeAttr('disabled')
      requiredElem.removeClass('keep-disabled')
    }
  })
})

function manageProjectRegistrationFields() {
  $('#project-registration-fields tbody tr').each(function(){
    var displayInput = $(this).find('td.display-checkbox input')
    var requiredInput = $(this).find('td.required-checkbox input')

    if(displayInput.is(':checked')) {
      if(!requiredInput.hasClass('keep-disabled')) {
        requiredInput.removeAttr('disabled')
      }
    } else {
      requiredInput.prop('disabled', true)
      requiredInput.prop('checked', false)
    }
  })
}

$(document).ready(function(){
  showProductsReceiptsLinks()

  $('.products-receipts-associations td.receipt-links a.show-more').click(function(){
    showProductsReceiptsLinks($(this))
  })

  $('.products-receipts-associations a.show-all').click(function(){
    var elem = $(this).parent().parent().find(".receipt-links")
    elem.find("span.receipt-link").removeClass('d-none')
    elem.find('a.show-more').remove()
  })
})

function showProductsReceiptsLinks(e) {
  if(e != undefined) {
    var columnWithLinks = e.parent()
  } else {
    var columnWithLinks = $('.products-receipts-associations td.receipt-links')
  }

  columnWithLinks.each(function(){
    $(this).find("span.receipt-link.d-none:lt(10)").removeClass('d-none')

    if(!$(this).find("span.receipt-link.d-none").length) {
      $(this).find('a.show-more').remove()
    }
  })
}

$(document).ready(function() {
  $(document).on('click', '.toggle-all-accesses-switchers', function() {
    $('.user-accesses-switcher').trigger('click')
  })

  $('a.promotivation-balance').click(function(){
    var url = $(this).data('url')

    $('#promotivation-balance-modal').modal('show')

    $.get(url)
  })

  $('.download-all-winners-report').click(function(){
    var url = $(this).data('url');
    var dataTableSearchValue = $('#winners_table_wrapper .dataTables_filter input').val();

    if(url.includes('?')) {
      url = url + "&search=" + dataTableSearchValue
    } else {
      url = url + "?search=" + dataTableSearchValue
    }

    $.get(url)
  })
})
