import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';

import ReceiptsContragents from './contragents/receipts';
import MainAnalyticsParticipants from './main_analytics/participants';
import MainAnalyticsValidReceipts from './main_analytics/valid_receipts';
import MainAnalyticsValidCodes from './main_analytics/valid_codes';
import MainAnalyticsReceiptsByParticipant from './main_analytics/receipts_by_participant';
import MainAnalyticsCodesByParticipant from './main_analytics/codes_by_participant';
import MainAnalyticsPrizes from './main_analytics/prizes';
import MainAnalyticsCommonDynamics from './main_analytics/common_dynamics';
import MainAnalyticsConversionFunnel from './main_analytics/conversion_funnel';
import RegistedMembers from './registed-members/registed-members';
import RegistedMembersPeriod from './registed-members/registed-members-period';
import RegistedMembersTotal from './registed-members/registed-members-total';
import RegistedMembersPeriodTotal from './registed-members/registed-members-period-total';
import TotalCostMembers from './registed-members/total-cost-members';
import MoneyMembers from './registed-members/money-members';
import RegistedCodes from './registed-codes/registed-codes';
import ParticipantsReceipts from './registed-receipts/participants-receipts';
import RegistedReceipts from './registed-receipts/registed-receipts';
import TotalCostReceipts from './registed-receipts/total-cost-receipts';
import MoneyReceipts from './registed-receipts/money-receipts';
import TotalCostCodes from './registed-codes/total-cost-codes';
import MoneyCodes from './registed-codes/money-codes';
import RegistedCodesPeriod from './registed-codes/registed-codes-period';
import RegistedReceiptsPeriod from './registed-receipts/registed-receipts-period';
import RegistedCodesTotal from './registed-codes/registed-codes-total';
import RegistedReceiptsTotal from './registed-receipts/registed-receipts-total';
import RegistedReceiptsPeriodTotal from './registed-receipts/registed-receipts-period-total';
import RegistedCodesOnMember from './registed-codes-on-member/registed-codes-on-member';
import RegistedReceiptsOnMember from './registed-receipts-on-member/registed-receipts-on-member';
import RegistedCodesOnMemberWeekly from './registed-codes-on-member/registed-codes-on-member-weekly';
import RegistedReceiptsOnMemberWeekly from './registed-receipts-on-member/registed-receipts-on-member-weekly';
import UserRegistrationChannels1 from './user-registration-channels/user-registration-channels_1';
import UserRegistrationChannels2 from './user-registration-channels/user-registration-channels_2';
import RegistartionChannelsDynamic from './user-registration-channels/registartion_channels_dynamic';
import IncorrectCodes1 from './incorrect-codes/incorrect-codes_1';
import IncorrectReceipts1 from './incorrect-receipts/incorrect-receipts_1';
import ReceiptsModerationStatus from './receipts-status/receipts_moderation_status';
import ReceiptsBySku from './receipts-status/receipts_by_sku';
import IncorrectCodes2 from './incorrect-codes/incorrect-codes_2';
import IncorrectReceipts2 from './incorrect-receipts/incorrect-receipts_2';
import SkuAllocation from './sku-allocation/sku-allocation';
import ProductPopularity1 from './product-popularity/product-popularity_1';
import ProductPopularity2 from './product-popularity/product-popularity_2';
import SkuTrands from './sku-trands/sku-trands';
import Audience1 from './audience/audience_1';
import Audience2 from './audience/audience_2';
import AudienceContragents from './contragents/audience';
import PointsConversion from './points-conversion/points-conversion';
import PointsConversionWeekly from './points-conversion/points-conversion-weekly';
import PrizesPlayed from './prizes-selected/prizes-played';
import PrizesGuaranteed from './prizes/guaranteed';
import PrizesSelected1 from './prizes-selected/prizes-selected_1';
import PrizesSelected2 from './prizes-selected/prizes-selected_2';
import PrizesBaltikaZg from './prizes-selected/prizes-baltika-zg';
import GaUserGeo from './new-charts/ga_user_geo';
import GaChannels from './new-charts/ga_channels';
import Feedbacks from './new-charts/feedbacks';
import Behaviours from './new-charts/behaviours';
import GeoRegistration from './geo/geo_registration';
import ContragentRetailPlacesMap from './contragents/retail_places_map';
import RetailPlaceMap from './contragents/retail_place_map';
import GeoCodes from './geo/geo_codes';
import GeoPrizeOrders from './geo/geo_prize_orders';
import GeoReceiptsRetailPlaces from './geo/geo_receipts_retail_places';
import GraphicProjectTests from './monitoring/graphic_project_tests';
import RegisteredMembersMilVsAll from './geo/registered-members-mil-vs-all';
import RegisteredCodesMilVsAll from './geo/registered-codes-mil-vs-all';
import RegisteredPrizeOrdersMilVsAll from './geo/registered-prize-orders-mil-vs-all';
import LogItemsHour from './log_items/hour';
import LogItemsDay from './log_items/day';
import LogItemsMonth from './log_items/month';

class Charts {
  constructor() {
  }

  static onInit() {
    Chart.defaults.multicolorLine = Chart.defaults.line;
    Chart.controllers.multicolorLine = Chart.controllers.line.extend({
      draw: function(ease) {
        let
          startIndex = 0,
          meta = this.getMeta(),
          points = meta.data || [],
          colors = this.getDataset().colors,
          area = this.chart.chartArea,
          originalDatasets = meta.dataset._children
            .filter(function(data) {
              return !isNaN(data._view.y);
            });

        function _setColor(newColor, meta) {
          meta.dataset._view.borderColor = newColor;
        }

        if (!colors) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          return;
        }

        for (let i = 2; i <= colors.length; i++) {
          if (colors[i-1] !== colors[i]) {
            _setColor(colors[i-1], meta);
            meta.dataset._children = originalDatasets.slice(startIndex, i);
            meta.dataset.draw();
            startIndex = i - 1;
          }
        }

        meta.dataset._children = originalDatasets.slice(startIndex);
        meta.dataset.draw();
        meta.dataset._children = originalDatasets;

        points.forEach(function(point) {
          point.draw(area);
        });
      }
    });
    Chart.Legend.prototype.afterFit = function() {
      this.height = this.height + 15;
    };
    MainAnalyticsParticipants.onInit();
    MainAnalyticsValidReceipts.onInit();
    MainAnalyticsValidCodes.onInit();
    MainAnalyticsReceiptsByParticipant.onInit();
    MainAnalyticsCodesByParticipant.onInit();
    MainAnalyticsPrizes.onInit();
    MainAnalyticsCommonDynamics.onInit();
    MainAnalyticsConversionFunnel.onInit()
    ReceiptsContragents.onInit();
    RegistedMembers.onInit();
    RegistedMembersPeriod.onInit();
    RegistedMembersTotal.onInit();
    RegistedMembersPeriodTotal.onInit();
    TotalCostMembers.onInit();
    MoneyMembers.onInit();
    RegistedCodes.onInit();
    ParticipantsReceipts.onInit();
    RegistedReceipts.onInit();
    TotalCostReceipts.onInit();
    MoneyReceipts.onInit();
    TotalCostCodes.onInit();
    MoneyCodes.onInit();
    RegistedCodesPeriod.onInit();
    RegistedReceiptsPeriod.onInit();
    RegistedCodesTotal.onInit();
    RegistedReceiptsPeriodTotal.onInit();
    RegistedReceiptsTotal.onInit();
    RegistedCodesOnMember.onInit();
    RegistedReceiptsOnMember.onInit();
    RegistedCodesOnMemberWeekly.onInit();
    RegistedReceiptsOnMemberWeekly.onInit();
    UserRegistrationChannels1.onInit();
    UserRegistrationChannels2.onInit();
    RegistartionChannelsDynamic.onInit();
    IncorrectCodes1.onInit();
    IncorrectReceipts1.onInit();
    ReceiptsModerationStatus.onInit();
    ReceiptsBySku.onInit();
    IncorrectCodes2.onInit();
    IncorrectReceipts2.onInit();
    SkuAllocation.onInit();
    ProductPopularity1.onInit();
    ProductPopularity2.onInit();
    SkuTrands.onInit();
    Audience1.onInit();
    Audience2.onInit();
    AudienceContragents.onInit();
    PointsConversion.onInit();
    PointsConversionWeekly.onInit();
    PrizesPlayed.onInit();
    PrizesGuaranteed.onInit();
    PrizesSelected1.onInit();
    PrizesSelected2.onInit();
    PrizesBaltikaZg.onInit();
    GaUserGeo.onInit();
    GaChannels.onInit();
    Feedbacks.onInit();
    Behaviours.onInit();
    GeoRegistration.onInit();
    ContragentRetailPlacesMap.onInit();
    RetailPlaceMap.onInit();
    GeoCodes.onInit();
    GeoReceiptsRetailPlaces.onInit();
    GeoPrizeOrders.onInit();
    GraphicProjectTests.onInit();
    RegisteredMembersMilVsAll.onInit();
    RegisteredCodesMilVsAll.onInit();
    RegisteredPrizeOrdersMilVsAll.onInit();
    LogItemsHour.onInit();
    LogItemsDay.onInit();
    LogItemsMonth.onInit();
  }
}

export default Charts;
