import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class LogItemsHour {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts_log_items_month');
    let check = $('canvas').is('#charts_log_items_month');

    let sectionParams = parseUrl('section')
    let project_id = parseUrl('project_id')
    let urlParams = "period=" + 'month' +
                    "&section=" + sectionParams +
                    "&project_id=" + project_id

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/log_items?' + urlParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.data[0] == 0 && response.data[1] == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: [ colors.blue[300], colors.red[300] ],
              hoverBackgroundColor: [ colors.blue[500], colors.red[500] ],
              borderColor: [ colors.blue[500], colors.red[500] ],
              hoverBorderColor: [ colors.blue[500], colors.red[500] ],
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          }
        });
      }
    }
  }

  static onInit() {
    LogItemsHour.Chart();
  }
}

export default LogItemsHour;
