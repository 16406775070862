import consumer from "./consumer"

$(document).ready(function(){
  var sessionUid = $("#tg_registration_session_uid").data('id')

  consumer.subscriptions.create({ channel: "LandingAuthorizationChannel", session_uid: sessionUid }, {
    received(data) {
      autoAuthorization()
    }
  })
})
