// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import Azia from './azia/index';
import Components from './components/components';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';
import Datetimepicker from './components/datetimepicker';
window.Datetimepicker = Datetimepicker;

import { parseUrl } from 'src/custom';
window.parseUrl = parseUrl;

import SQLParser from 'src/sql_parser';
window.SQLParser = SQLParser;

import datepickerFactory from 'jquery-datepicker';
import datepickerRUFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ru';

import codemirror from 'javascripts/codemirror'
window.codemirror = window.CodeMirror = codemirror

datepickerFactory($);
datepickerRUFactory($);
$.datepicker.regional['ru'];

import 'fullcalendar';

require('@rails/ujs').start()
// require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('jvectormap')
require('wheelzoom')
require('@glidejs/glide')
require("imports-loader?define=>false!jquery-mousewheel")($)
require('packs/ru-mill')
require('src/cocoon')
require('src/custom')
require('src/select2.min.js')
require('src/select2.ru.js')
require('src/datatables.min')
require('packs/tickets')
require('src/cropper')
require('packs/products')
require('packs/receipts')
require('packs/codes')
require('packs/dashboard')
require('packs/projects')
require('packs/tags')
require('packs/calendar')
require('packs/communication_campaigns')
require('packs/bootstrap.bundle.min.js')
require('src/custom_for_new_template')
require('packs/components/codemirror/codemirror/modes/yaml')

import('src/query-builder.standalone')

class App {
  constructor() {
    Azia.onInit();
    this.components = new Components();
    Datetimepicker.onInit();
    Receipts.init();
    Codes.init();
    Calendar.init();
  }
}
export default App;

window.App = new App()

jQuery.extend(true, jQuery.fn.dataTable.defaults, {
  'language': {
    'emptyTable': 'Нет данных',
    'lengthMenu': 'Показывать _MENU_ записей',
    'search': 'Поиск: ',
    'paginate': {
      'first': 'первая',
      'last': 'последняя',
      'next': 'следующая',
      'previous': 'предыдущая'
    },
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'stylesheets/application';
import "ionicons/dist/css/ionicons.css"
require("trix")
require("@rails/actiontext")
